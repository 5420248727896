.navbar {
  background: #545d45;
  box-shadow: 0 10px 4px -2px rgba(0, 0, 0, 0.2);
  height: 100px;
  display: flex;
  font-size: 1.5rem;
  position: sticky;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  max-width: 1500px;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  width: 83vw;
  justify-content: flex-end;
  margin-right: 2rem;
}

.nav-item {
  display: flex;
  align-content: center;
  margin-left: 15px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 80px;
    left: -100%;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    transition: all 0.5s ease;
    z-index: 3;
    padding: 0;
    margin-top: 10px;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-150%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
/* Checked */
