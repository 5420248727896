* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: sans-serif, "Times New Roman", Times, serif;
}

.body {
  background-color: #ededde;
}

.home,
.get,
.map,
.login {
  background-color: black;
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.carousel-item {
  max-height: 90vh;
}

.carousel-caption {
  top: 50%;
}

.carousel-caption h1 {
  transform: translateY(-50%);
  text-shadow: 2px 2px black;
  margin-left: 20px;
  text-align: center;
  font-size: 70px;
}

@media only screen and (max-width: 900px) and (min-width: 400px) {
  .carousel-caption h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 900px) and (min-width: 200px) {
  .carousel-caption h1 {
    font-size: 30px;
  }
}

.card-group {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: #fff;
  transition: all 0.5s ease;
  cursor: pointer;
  user-select: none;
  z-index: 10;
  overflow: hidden;
}

.card:hover {
  transform: scale(1.025);
}

.accordion {
  width: 90%;
}

/* Checked */
